/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a className="font-weight-bold ml-1" href="#">
                  GSR Training Consulting
                </a>
                <div className="social-links d-block mt-3">
                  <a className="social-link mx-2"
                    href="https://web.facebook.com/GSR-Consulting-107161004776627"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      height="30px"
                      style={{
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 2,
                      }}
                      version="1.1"
                      viewBox="0 0 512 512"
                      width="30px"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsSerif="http://www.serif.com/"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          d="M512,256c0,-141.385 -114.615,-256 -256,-256c-141.385,0 -256,114.615 -256,256c0,127.777 93.616,233.685 216,252.89l0,-178.89l-65,0l0,-74l65,0l0,-56.4c0,-64.16 38.219,-99.6 96.695,-99.6c28.009,0 57.305,5 57.305,5l0,63l-32.281,0c-31.801,0 -41.719,19.733 -41.719,39.978l0,48.022l71,0l-11.35,74l-59.65,0l0,178.89c122.385,-19.205 216,-125.113 216,-252.89Z"
                          style={{ fill: "#1877f2", fillRule: "nonzero" }}
                        />
                        <path
                          d="M355.65,330l11.35,-74l-71,0l0,-48.022c0,-20.245 9.917,-39.978 41.719,-39.978l32.281,0l0,-63c0,0 -29.297,-5 -57.305,-5c-58.476,0 -96.695,35.44 -96.695,99.6l0,56.4l-65,0l0,74l65,0l0,178.89c13.033,2.045 26.392,3.11 40,3.11c13.608,0 26.966,-1.065 40,-3.11l0,-178.89l59.65,0Z"
                          style={{ fill: "#fff", fillRule: "nonzero" }}
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    className="social-link"
                    href="https://www.instagram.com/p/CMLLYKHno_H/?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <svg
                      enableBackground="new 0 0 1024 1024"
                      height="30px"
                      id="Instagram_2_"
                      version="1.1"
                      viewBox="0 0 1024 1024"
                      width="30px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Background">
                        <linearGradient
                          gradientTransform="matrix(0.9397 0.3421 0.3421 -0.9397 276.2042 765.8284)"
                          gradientUnits="userSpaceOnUse"
                          id="bg_1_"
                          x1="463.9526"
                          x2="-194.4829"
                          y1="-73.1143"
                          y2="711.4479"
                        >
                          <stop offset="0" stopColor="#20254C" />
                          <stop offset="0.0571" stopColor="#29254D" />
                          <stop offset="0.1502" stopColor="#41234F" />
                          <stop offset="0.2679" stopColor="#692152" />
                          <stop offset="0.4039" stopColor="#A01F57" />
                          <stop offset="0.5333" stopColor="#DA1C5C" />
                          <stop offset="0.5924" stopColor="#DC255A" />
                          <stop offset="0.6889" stopColor="#E13D56" />
                          <stop offset="0.8106" stopColor="#EA654E" />
                          <stop offset="0.9515" stopColor="#F69C44" />
                          <stop offset="1" stopColor="#FBB040" />
                        </linearGradient>
                        <circle
                          cx="512.001"
                          cy="512"
                          fill="url(#bg_1_)"
                          id="bg"
                          r="512"
                        />
                      </g>
                      <g id="Instagram_3_">
                        <circle
                          cx="658.765"
                          cy="364.563"
                          fill="#FFFFFF"
                          r="33.136"
                        />
                        <circle
                          cx="512.001"
                          cy="512"
                          fill="none"
                          r="121.412"
                          stroke="#FFFFFF"
                          stroke-miterlimit="10"
                          stroke-width="45"
                        />
                        <path
                          d="M255.358,612.506c0,91.127,73.874,165,165,165   h183.283c91.127,0,165-73.873,165-165V411.495c0-91.127-73.873-165-165-165H420.358c-91.127,0-165,73.873-165,165V612.506z"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-miterlimit="10"
                          stroke-width="45"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    className="social-link mx-2"
                    href="https://www.linkedin.com/in/gsr-consulting-325230248"
                    target="_blank"
                  >
                    <svg
                      height="30px"
                      style={{
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 2,
                      }}
                      version="1.1"
                      viewBox="0 0 512 512"
                      width="30px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="g5891">
                        <path
                          d="M512,64c0,-35.323 -28.677,-64 -64,-64l-384,0c-35.323,0 -64,28.677 -64,64l0,384c0,35.323 28.677,64 64,64l384,0c35.323,0 64,-28.677 64,-64l0,-384Z"
                          id="background"
                          style={{ fill: "#2867b2" }}
                        />
                        <g id="shapes">
                          <rect
                            height="30"
                            id="rect11"
                            style={{ fill: "#fff" }}
                            width="30"
                            x="61.053"
                            y="178.667"
                          />
                          <path
                            d="M104.512,54.28c-29.341,0 -48.512,19.29 -48.512,44.573c0,24.752 18.588,44.574 47.377,44.574l0.554,0c29.903,0 48.516,-19.822 48.516,-44.574c-0.555,-25.283 -18.611,-44.573 -47.935,-44.573Z"
                            id="path13-0"
                            style={{ fill: "#fff", fillRule: "nonzero" }}
                          />
                          <path
                            d="M357.278,172.601c-45.49,0 -65.866,25.017 -77.276,42.589l0,-36.523l-85.738,0c1.137,24.197 0,257.961 0,257.961l85.737,0l0,-144.064c0,-7.711 0.554,-15.42 2.827,-20.931c6.188,-15.4 20.305,-31.352 43.993,-31.352c31.012,0 43.436,23.664 43.436,58.327l0,138.02l85.741,0l0,-147.93c0,-79.237 -42.305,-116.097 -98.72,-116.097Z"
                            id="path15"
                            style={{ fill: "#fff", fillRule: "nonzero" }}
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a
                    className="social-link mx-2"
                    href="https://www.youtube.com/c/SamuelTengey"
                    target="_blank"
                  >
                    <svg
                      height="35px"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      version="1.1"
                      viewBox="0 0 512 512"
                      width="35px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="_x33_95-youtube">
                        <g>
                          <path
                            d="M476.387,144.888c-5.291-19.919-20.878-35.608-40.67-40.933C399.845,94.282,256,94.282,256,94.282    s-143.845,0-179.719,9.674c-19.791,5.325-35.378,21.013-40.668,40.933c-9.612,36.105-9.612,111.438-9.612,111.438    s0,75.334,9.612,111.438c5.29,19.92,20.877,34.955,40.668,40.281C112.155,417.719,256,417.719,256,417.719    s143.845,0,179.717-9.674c19.792-5.326,35.379-20.361,40.67-40.281c9.612-36.104,9.612-111.438,9.612-111.438    S485.999,180.994,476.387,144.888z"
                            style={{ fill: "#FF0000" }}
                          />
                          <polygon
                            points="208.954,324.723 208.954,187.93 329.18,256.328"
                            style={{ fill: "#FFFFFF" }}
                          />
                        </g>
                      </g>
                      <g id="Layer_1" />
                    </svg>
                  </a>
                </div>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink href="#">GSR Training Consulting</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#">About Us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="">Blog</NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
