import React from "react";
import { Table } from "reactstrap";

export default class CalendarTable extends React.Component {
  state = {
    calendarData: [
      {
        id: 1,
        name: "Public Relations Category",
        file: "PUBLIC RELATIONS CATEGORY.docx",
      },
      {
        id: 2,
        name: "Public Private Partnerships (PPP) Category",
        file: "Public Private Partnerships (PPP) Category.docx",
      },
      {
        id: 3,
        name: "Project Management Category",
        file: "Project Management Category.docx",
      },
      {
        id: 4,
        name: "Procurement, Logistics and Supply Chain Management Category",
        file: "Procurement, Logistics and Supply Chain Management Category.docs",
      },
      {
        id: 5,
        name: "Personal & Professional Development Category",
        file: "Personal & Professional Development Category.docx",
      },
      {
        id: 6,
        name: "Oil & Gas Category",
        file: "OIL & GAS CATEGORY.docx",
      },
      {
        id: 7,
        name: "GSR Office Adminstration Category",
        file: "GSR Office Adminstration Category.docx",
      },
      {
        id: 8,
        name: "GSR National Security Management Category",
        file: "GSR National Security Management Category.docx",
      },
      {
        id: 9,
        name: "GSR Media and Telecom Category",
        file: "GSR Media and Telecom Category.docx",
      },
      {
        id: 10,
        name: "Account and Finance Courses-2025",
        file: "Account and Finance Courses-2025",
      },
      {
        id: 11,
        name: "AGRIBUSINESS SUSTAINABILITY",
        file: "AGRIBUSINESS SUSTAINABILITY",
      },
      {
        id: 12,
        name: "Audit, Compliance & Governance Categoory-2025",
        file: "AUDIT, COMPLIANCE & GOVERNANCE CATEGORY-2025.docx",
      },
      {
        id: 13,
        name: "Banking and Investment Insurance-2025",
        file: "Banking and investment insurance-2025.docx",
      },
      {
        id: 14,
        name: "Banking and Investment Insurance 2025",
        file: "Banking and investment insurance-not complete.docx",
      },
      {
        id: 15,
        name: "Communication & Writing.docx",
        file: "COMMUNICATION & WRITING (1).docx",
      },
      {
        id: 16,
        name: "Construction Management & Civil Engeering Courses",
        file: "Construction Management & Civil Engeering Courses.docx",
      },
      {
        id: 17,
        name: "Contract Management & Law",
        file: "Contract Management & Law.docx",
      },
      {
        id: 18,
        name: "Customer Service Courses",
        file: "Customer Service Courses.docx",
      },
      {
        id: 19,
        name: "Data Management & Business Intelligence",
        file: "Data management & Business Intelligence.docx",
      },
      {
        id: 20,
        name: "Electrical Engineering Courses",
        file: "Electrical Engineering Courses.docx",
      },
      // {
      //   id: 21,
      //   name: "GRS 2025 Oversees Training Course Categories",
      //   file: "GRS 2025 Oversees Training Course Categories.docx",
      // },
      {
        id: 21,
        name: "GSR Health, Safety and Environment (HSE) Category",
        file: "GSR Health, Safety and Environment (HSE) Category.docx",
      },
      {
        id: 22,
        name: "GSR Health, Safety and Environment (HSE) Category",
        file: "GSR Health, Safety and Environment (HSE) Category (1).docx",
      },
      {
        id: 23,
        name: "GSR Human Resource Management Category",
        file: "GSR Human Resource Management Category.docx",
      },
      {
        id: 24,
        name: "GSR Humanitarian Development Category",
        file: "GSR Humanitarian Development Category.docx",
      },
      {
        id: 25,
        name: "GSR Instrumentation and Process Control Category",
        file: "GSR Instrumentation and Process Control Category.docx",
      },
      {
        id: 26,
        name: "GSR Mechanical Engeering Category",
        file: "GSR Mechanical Engeering Category.docx",
      },
      {
        id: 27,
        name: "GSR Learning and Development Category",
        file: "GSR Learning and Development Category.docx",
      },
      {
        id: 28,
        name: "GSR Maintenance Engineering Category",
        file: "GSR Maintenance Engineering Category.docx",
      },
      {
        id: 29,
        name: "Commnunication & Writing",
        file: "COMMUNICATION & WRITING.docx",
      },
      {
        id: 30,
        name: "Strategy and Strategic Planning Category",
        file: "Strategy and Strategic Planning Category.docx",
      },
      {
        id: 31,
        name: "Sales and Marketing Category",
        file: "Sales and Marketing Category.docx",
      },
      {
        id: 32,
        name: "Public Sector Management Category",
        file: "Public Sector Management Category.docx",
      },
      {
        id: 33,
        name: "Quality Control & Risk Management Category",
        file: "Quality Control & Risk Management Category (1).docx",
      },
    ],
  };

  render() {
    return (
      <Table striped style={{ borderTop: "none", textAlign: "left" }}>
        <thead>
          <tr>
            <th style={{ fontSize: "16px" }}>#</th>
            <th style={{ fontSize: "16px" }}>DOCUMENT</th>
            <th style={{ fontSize: "16px", textAlign: "right" }}>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          {this.state.calendarData.map((value, index) => (
            <tr>
              <th>{index + 1}</th>
              <th>{value.name}</th>
              <td style={{ textAlign: "right" }}>
                <a
                  href={`https://backends.resourcegsr.com/oversees-training/${value.file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-eye mr-4"
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                  />
                </a>
                <a
                  href={`https://backends.resourcegsr.com/api/download/${value.file}/file`}
                  download
                >
                  <i
                    className="fa fa-download mr-3"
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                  />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
